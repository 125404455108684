<template>
  <v-row class="fill-height">
    <div
      v-if="!loaded"
      class="text-center mt-5 mb-5"
      style="width: 100%; margin-right: auto; margin-left: auto"
    >
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
    </div>
    <v-col
      v-if="loaded"
      :class="$root.mobile ? 'p-0' : ''"
      :style="$root.mobile ? 'margin-top:-19px; margin-bottom:-16px;' : ''"
    >
      <v-sheet height="64">
        <v-toolbar flat color="white">
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            {{ $t("APPOINTMENTS.CAL.today") }}
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>{{
                  $t("APPOINTMENTS.CAL.day")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>{{
                  $t("APPOINTMENTS.CAL.week")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>{{
                  $t("APPOINTMENTS.CAL.month")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>{{
                  $t("APPOINTMENTS.CAL.days")
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          :locale="this.$root.lang"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <router-link
                v-if="selectedEvent.date_time > new Date().toISOString()"
                :to="'/medical_file/' + selectedEvent.mfId"
                v-slot="{ href, navigate }"
              >
                <v-btn
                  icon
                  v-b-tooltip.hover.top="$t('APPOINTMENTS.APP.actions.md')"
                  :href="href"
                  @click="navigate"
                >
                  <v-icon>mdi-file-account</v-icon>
                </v-btn>
              </router-link>
              <v-btn
                icon
                v-b-tooltip.hover.top="$t('APPOINTMENTS.APP.actions.delete')"
                @click="deleteAppointment(selectedEvent.mfId)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <div class="d-flex align-items-center">
                <span
                  v-if="selectedEvent.avatar"
                  class="symbol symbol-circle symbol-60 symbol-light-primary mr-3"
                >
                  <img alt="Pic" :src="selectedEvent.avatar" />
                </span>
                <span
                  v-else
                  class="symbol symbol-circle symbol-60 symbol-primary mr-3"
                >
                  <span
                    v-if="selectedEvent.name"
                    class="symbol-label font-size-h1 font-weight-bold"
                    style="text-transform: uppercase"
                    >{{ selectedEvent.name.charAt(0) }}</span
                  >
                </span>
                <div class="d-flex flex-column text-left">
                  <span class="text-dark-75 font-weight-bold"
                    >Gender : {{ selectedEvent.gender }}</span
                  >
                  <span class="text-dark-75 font-weight-bold"
                    >Date of Birth : {{ selectedEvent.dob }}</span
                  >
                  <span class="text-dark-75 font-weight-bold"
                    >Height : {{ selectedEvent.height }}</span
                  >
                  <span class="text-dark-75 font-weight-bold"
                    >Weight : {{ selectedEvent.weight }}</span
                  >
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="primary" @click="selectedOpen = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "AppointmentCalendar",
  data: () => ({
    loaded: false,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days"
    },
    error: "",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1"
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party"
    ]
  }),
  mounted() {
    this.getAllAppointments();
    //this.$refs.calendar.checkChange()
  },
  methods: {
    getTimeZone() {
      return localStorage.timezone;
    },
    async getAllAppointments() {
      const events = [];

      var userInfo = JSON.parse(localStorage.userInfo).id;
      await ApiService.get(
        "items",
        "appointments?fields=id,status,video_room_id,rating,type,date,time,date_time,medical_file.owner.id,medical_file.id,medical_file.owner.first_name,medical_file.owner.last_name,medical_file.owner.avatar.data,medical_file.birthday,medical_file.gender,medical_file.height,medical_file.weight,rating&filter[doctor.doctor_id.id]=" +
          userInfo
      )
        .then(({ data }) => {
          ////console.log(data);
          this.$moment.locale("en");
          for (var item in data.data) {
            ////console.log(data.data[item]);
            ////console.log("start time ", data.data[item].date_time);
            var ava;
            if (data.data[item].medical_file.owner.avatar) {
              ava =
                data.data[item].medical_file.owner.avatar.data.thumbnails[0]
                  .url;
            } else {
              ava = null;
            }
            // console.log("item", data.data[item]);
            if (data.data[item].date_time == null) {
              continue;
            }
            events.push({
              mfId: data.data[item].medical_file.id,
              name:
                data.data[item].medical_file.owner.first_name +
                " " +
                data.data[item].medical_file.owner.last_name,
              start: this.$moment
                .utc(data.data[item].date_time + "Z")
                .tz(localStorage.timezone)
                .format("YYYY-MM-DD HH:mm"),
              end: this.$moment
                .utc(data.data[item].date_time + "Z")
                .tz(localStorage.timezone)
                .add(JSON.parse(localStorage.SETTINGS).call_duration, "m")
                .format("YYYY-MM-DD HH:mm"),
              color: "primary",
              test: "hi",
              avatar: ava,
              gender: data.data[item].medical_file.gender,
              dob: data.data[item].medical_file.birthday,
              height: data.data[item].medical_file.height,
              weight: data.data[item].medical_file.weight,
              date_time: data.data[item].date_time,
              timed: true
            });
          }
          this.$moment.locale(localStorage.language);
          this.events = events;
          this.loaded = true;
        })
        .catch(error => {
          this.error = error;
        });
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async deleteAppointment(id) {
      const payload = { status: "deleted" };
      this.loaded = true;
      await ApiService.update("items", `appointments/${id}`, payload);
      this.getAllAppointments();
      this.loaded = false;
    }
  }
};
</script>
<style scoped></style>
