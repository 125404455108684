<template>
  <div>
    <div data-app v-if="!this.$root.mobile">
      <div class="card card-custom gutter-b">
        <b-card no-body>
          <b-tabs pills card>
            <b-tab class="p-0" :title="$t('APPOINTMENTS.TABS.calendar')" active>
              <b-card-text>
                <AppointmentCalendar />
              </b-card-text>
            </b-tab>
            <b-tab class="p-0" :title="$t('APPOINTMENTS.TABS.upcoming')"
              ><b-card-text><AppointmentUpcoming /></b-card-text
            ></b-tab>
            <b-tab class="p-0" :title="$t('APPOINTMENTS.TABS.previous')"
              ><b-card-text><AppointmentPrevious /></b-card-text
            ></b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <div v-else>
     
      <div class="mb-3" style="display: flex; justify-content: center">
        <v-btn-toggle
          v-model="tab"
          mandatory
          rounded
          class="mx-0 blue primary--text"
        >
          <v-btn active-class="activebtn" width="31vw" class="primary--text">
            {{ $t("APPOINTMENTS.TABS.calendar") }}
          </v-btn>
          <v-btn active-class="activebtn" width="31vw" class="primary--text">
            {{ $t("APPOINTMENTS.TABS.upcoming") }}
          </v-btn>
          <v-btn active-class="activebtn" width="31vw" class="primary--text">
            {{ $t("APPOINTMENTS.TABS.previous") }}
          </v-btn>
        </v-btn-toggle>
      </div>

      <div v-if="tab == 0" :style="checkMargin()">
        <transition name="fade-in-up">
          <AppointmentCalendar />
        </transition>
      </div>

      <div v-if="tab == 1">
        <transition name="fade-in-up">
          <AppointmentUpcoming :skltoload="5" />
        </transition>
      </div>

      <div v-if="tab == 2">
        <transition name="fade-in-up">
          <AppointmentPrevious />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppointmentCalendar from "./comp/appointments/appCalendar.vue";
import AppointmentUpcoming from "./comp/appointments/appUpcoming.vue";
import AppointmentPrevious from "./comp/appointments/appPrevious.vue";

export default {
  name: "appointments",
  components: {
    AppointmentCalendar,
    AppointmentUpcoming,
    AppointmentPrevious,
  },
  data() {
    return {
      tabIndex: 0,
      showCalendar: true,
      showupcoming: false,
      showprevious: false,
      active_tab: ["active_tab", "", ""],
      tab:0,
    };
  },
  computed: {},
  mounted() {
    this.$root.title = this.$t("MENU.appointment");
  },
  destroyed() {},
  methods: {
    checkMargin() {
      if (this.$root.mobile) {
        return "margin-top:30px !important";
      }
      return "";
    },
    switchTab(clicked) {
      if (clicked == 0) {
        this.showCalendar = true;
        this.showupcoming = false;
        this.showprevious = false;
        this.active_tab[0] = "active_tab";
        this.active_tab[1] = "";
        this.active_tab[2] = "";
      } else if (clicked == 1) {
        this.showCalendar = false;
        this.showupcoming = true;
        this.showprevious = false;
        this.active_tab[0] = "";
        this.active_tab[1] = "active_tab";
        this.active_tab[2] = "";
      } else if (clicked == 2) {
        this.showCalendar = false;
        this.showupcoming = false;
        this.showprevious = true;
        this.active_tab[0] = "";
        this.active_tab[1] = "";
        this.active_tab[2] = "active_tab";
      }
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
.v-application--wrap {
  min-height: 0px !important;
}
.activebtn {
  border-style: solid;
  border-color: #d2e1f2 !important;
  background: white !important;
  border-width: 4px !important;
  color: #0971ce !important;
}
</style>
<style scoped>
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) >>> .v-btn.v-btn {
  border-color: #d2e1f2 !important;
  border-width: 0px;
}

.v-btn:before {
  background-color: transparent !important;
}
</style>