<template>
  <v-card class="elevation-0" v-if="!this.$root.mobile">
    <v-card-title>
      {{ $t("APPOINTMENTS.TABS.upcoming") }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t('APPOINTMENTS.APP.search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="apps"
      :search="search"
      :loading="loadingStatus"
      :locale="this.$root.lang"
      :loading-text="$t('tLoading')"
    >
      <!-- #item.owner -->
      <template v-slot:item.medical_file.owner.first_name="{ item }">
        <Avatar :user="item.medical_file.owner"></Avatar>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="d-flex align-items-center">
          <span
            v-if="item.status == 'published'"
            class="dot-blue primary ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.pub')"
          ></span>
          <span
            v-if="item.status == 'deleted'"
            class="dot-red danger ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.del')"
          ></span>
          <span
            v-if="item.status == 'draft'"
            class="dot-red red ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.del')"
          ></span>
          <span
            v-if="item.status == 'progress'"
            class="dot-yellow warning ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.pro')"
          ></span>
          <span
            v-if="item.status == 'complete'"
            class="dot-green success ml-3"
            v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.a_status.com')"
          ></span>
        </div>
      </template>
      <template v-slot:item.date="{ item }">
        <div class="d-flex align-items-center">
          <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
            item.date
          }}</span>
        </div>
      </template>
      <template v-slot:item.time="{ item }">
        <div class="d-flex align-items-center">
          <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
            (item.date_time + "Z")
              | moment("timezone", getTimeZone(), "hh:mm A")
          }}</span>
          <span class="text-dark-75 font-weight-light font-size-sm"
            >({{
              (item.date_time + "Z")
                | moment("timezone", getTimeZone(), "from", "now")
            }})</span
          >
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        <i
          v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.type_d.video')"
          v-if="item.type == 'video'"
          class="fas fa-video"
        ></i>
        <i
          v-b-tooltip.hover.right="$t('APPOINTMENTS.APP.type_d.clinic')"
          v-if="item.type == 'clinic'"
          class="fas fa-user-friends"
        ></i>
      </template>
      <template v-slot:item.action="{ item }">
        <router-link
          :to="'/medical_file/' + item.medical_file.id"
          v-slot="{ href, navigate }"
        >
          <a :href="href" class="btn btn-primary mr-4" @click="navigate">
            <i class="flaticon2-medical-records-1"></i>File
          </a>
        </router-link>
        <a
          v-if="item.status != 'progress'"
          href="#"
          class="btn btn-danger mr-4"
          @click="deleteAppointment(item.id)"
        >
          <i class="flaticon2-trash"></i>Delete
        </a>
        <router-link
          :to="'/appointment/' + item.id"
          v-slot="{ href, navigate }"
          v-if="item.status == 'progress'"
        >
          <a :href="href" class="btn btn-success mr-4" @click="navigate">
            <v-icon class="">mdi-play-circle</v-icon>join
          </a>
        </router-link>
      </template>
    </v-data-table>
  </v-card>

  <!-- start for mobile -->
  <div class="bg-transparent" v-else>
    <div
      v-if="loadingStatus"
      class="text-center"
      style="width: 100%; margin-right: auto; margin-left: auto"
    >
      <v-skeleton-loader v-for="i in skltoload" :key="i" class="my-2" height="80"
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
    </div>
     
    <template v-else>
      <v-list class="bg-transparent">
      <template>
        <div v-for="item in apps" :key="item.id" class="mb-2 listitem">
          <v-list-item style="min-height:80px">
            <v-list-item-avatar size="47" color="primary">
              <v-img
                v-if="item.medical_file.owner.avatar != null"
                :src="item.medical_file.owner.avatar.data.thumbnails[0].url"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ item.medical_file.owner.first_name.charAt(0) }}
              </div>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                >
                <div class="listitemtitle" v-if="item.type == 'video'">{{ $t("APPOINTMENTS.APP.vidwith") }}<br/>
                {{ item.medical_file.owner.first_name }}
                {{ item.medical_file.owner.last_name }}
                </div>
                <div class="listitemtitle" v-else-if="item.type == 'clinic'">
                  {{ $t("APPOINTMENTS.APP.clinicwith") }}<br/>
                {{ item.medical_file.owner.first_name }}
                {{ item.medical_file.owner.last_name }}
                </div>
                <div class="listitemsubtitle">
                {{
                  (item.date_time + "Z")
                    | moment(
                      "timezone",
                      getTimeZone(),
                      "ddd DD/MM/YYYY hh:mm A"
                    )
                }}
                {{ (item.date_time + "Z")
                | moment("timezone", getTimeZone(), "from", "now") }}</div>
                </v-list-item-title
              >
              
              
             
            </v-list-item-content>
            <v-list-item-action>
              <v-btn  icon elevation="0" 
              @click="deleteAppointment(item.id)"
              v-if="item.status != 'progress'">
                   <unicon
              style="margin-right: auto;margin-left: auto;"
              name="trash-alt"
              fill="#f64e60"
              height="20"
            ></unicon>
                </v-btn>
                <router-link
                :to="'/medical_file/' + item.medical_file.id"
                v-slot="{ href, navigate }"
              >
                <v-btn icon :href="href" @click="navigate">
                  <unicon
                    name="file-medical-alt"
                    fill='#0971CE'
                    height="20"
                  ></unicon>
                </v-btn>
              </router-link>
              <router-link
                :to="'/appointment/' + item.id"
                v-slot="{ navigate }"
                v-if="item.status === 'progress'"
              >
              
                <v-btn small fab elevation="0" color="rgba(165, 203, 239, 0.20)" @click="navigate">
                  <unicon
                    style="margin-right: auto;margin-left: auto;"
                    name="video"
                    fill="#0971CE"
                    height="20"
                  ></unicon>
                </v-btn>
              </router-link>
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>
    </v-list>
    </template>
    <div
      class="text-center mt-10"
      v-if="apps.length == 0 && !loadingStatus"
    >
      <span class="text-muted font-size-h4">{{
        $t("APPOINTMENTS.APP.noappsfound")
      }}</span>
    </div>
  </div>
  <!-- end for mobile -->
</template>

<script>
import ApiService from "@/core/services/api.service";
import Avatar from "@/view/pages/utilComponents/avatar2.vue";

export default {
  name: "AppointmentUpcoming",
  components: { Avatar },
  props: ["skltoload"],
  data() {
    return {
      search: "",
      error: "",
      loadingStatus: true,
      headers: [
        {
          text: this.$t("APPOINTMENTS.APP.name"),
          align: "left",
          sortable: true,
          value: "medical_file.owner.first_name"
        },
        { text: this.$t("APPOINTMENTS.APP.status"), value: "status" },
        { text: this.$t("APPOINTMENTS.APP.date"), value: "date" },
        { text: this.$t("APPOINTMENTS.APP.time"), value: "time" },
        { text: this.$t("APPOINTMENTS.APP.type"), value: "type" },
        {
          text: this.$t("APPOINTMENTS.APP.action"),
          value: "action",
          align: "right"
        }
      ],
      apps: []
    };
  },
  mounted() {
    this.getAppointments();
  },
  methods: {
    async getAppointments() {
      this.$moment.locale("en");
      var userInfo = JSON.parse(localStorage.userInfo).id;

      await ApiService.get(
        "items",
        "appointments?fields=id,status,video_room_id,rating,type,date,time,date_time,medical_file.owner.id,medical_file.owner.first_name,medical_file.owner.last_name,medical_file.owner.avatar.data,medical_file.id,medical_file.birthday,medical_file.gender,rating,doctor.doctor_id.avatar.data,doctor.doctor_id.id&filter[doctor.doctor_id.id]=" +
          userInfo +
          "&filter[status][in]=progress,pending&sort=date_time" 
      )
        .then(({ data }) => {
          //console.log(data.data);
          this.apps = data.data;
          this.loadingStatus = false;
          this.$moment.locale(localStorage.language);
        })
        .catch(error => {
          this.error = error;
        });
    },
    getTimeZone() {
      return localStorage.timezone;
    },
    removefromlist(id)
    {
      var i = this.apps.findIndex((item) => {
        console.log("item",item)
        return item.id == id
        
      })
      console.log("index to be deleted", i);
      this.apps.splice(i, 1);
    },
    async deleteAppointment(id) {
      this.deletedialg = true;
      console.log(`delete app with id ${id}`);
      const payload = { status: "deleted" };
      //this.loadingStatus = true;
      await ApiService.update("items", `appointments/${id}`, payload);

      this.removefromlist(id);
      this.deletedialg = false;
      //this.getAppointments();
      //this.loadingStatus = false;
    }
  },
  computed: {
    filteredApps() {
      return this.apps.filter(
        app =>
          app.medical_file.owner.first_name
            .toLowerCase()
            .match(this.search.toLowerCase()) ||
          app.medical_file.owner.last_name
            .toLowerCase()
            .match(this.search.toLowerCase()) ||
          app.date.match(this.search)
      );
    }
  }
};
</script>
